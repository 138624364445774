@import 'now-ui-dashboard/variables';
@import 'bidatc/line';
@import 'bidatc/leave';

/** BASE */
p {
    word-break: break-word;
}

.dashboard {
    thead {
        font-size: 0.8em;
    }
}

.panel-header-sm {
    height: 50px;
    padding-top: 50px;
}

.footer {
    padding: 10px 0 24px 0;
}

.strikethrough {
    text-decoration: line-through;
}

.underline {
    text-decoration: underline;
}

/** HEADER */
.navbar .container-fluid {
  align-items: start;
}

/** SUBHEADER */
.main-panel > .content.push-content {
    margin-top: 25px;
}

.sub-navbar {
    width: 100%;
    margin-top: -29px;
    margin-left: 13px;
    min-height: 29px;
    padding: 0 1rem;

    ul {
        margin: 0;
    }

    .area-link:hover {
        padding-bottom: 3px;
        border-bottom: 5px solid gray;
        font-weight: bold;
        color: gray;
        -o-transition: .5s;
        -ms-transition: .5s;
        -moz-transition: .5s;
        -webkit-transition: .5s;
        transition: .5s;
    }

    .area-link-selected {
        padding-bottom: 3px;
        border-bottom: 5px solid green;
        font-weight: bolder;
    }

    @media (max-width: 768px) {
        min-height: 24px;
        font-size: 0.75em;
    }
}

/** AREA DROPDOWN */
.area-dropdown {
    a {
        text-decoration: none;
    }
    .selected {
        background-color: $success-2;
        color: $white-color;
        font-weight: bold;

        &:hover {
            background-color: $success-1;
        }

        a {
            color: $white-color;
            font-weight: bold;
        }
    }

    .el-dropdown-menu__item.selected:hover {
        background-color: $success-1;
    }
}

/** DIALOG (vuejs) */
.dg-container {
    .dg-title {
        color: $primary-2;
        font-weight: bolder;
        font-size: 1.5rem;
        border-bottom: 2px solid $primary-2;
    }

    .dg-btn--cancel {
        background-color: $gray-2;
    }

    .dg-btn--ok {
        background-color: $success-2;
        color: $white-color;
        border: none;
    }
}

/** CRUD RESULTS STYLE */
.results {
    //@media(max-width: 768px) {
    //    &.table {
    //        font-size: 0.6em;
    //    }
    //}

    &.table, &.table-responsive {
        width: 100%;
        margin: auto;
        padding: 0;
        border-collapse: collapse;
        table-layout: fixed;

        thead {
            background-color: $primary-3;
            color: $white-color;

            a, a:hover, a:active, a:visited {
                color: $white-color;
            }
        }

        td, th {
            padding: 0.3em;
            font-size: 0.8em;
            border: thin solid $gray-4;
        }

        th {
            font-size: 0.9em;
            font-weight: bold;
        }
    }

    .head {
        background-color: $primary-4;
        color: $white-color;
        font-weight: bold;
    }

    .subhead {
        background-color: $primary-1;
        color: $white-color;
        font-weight: bold;
    }

    .subhead-light {
        background-color: $light-gray;
        color: $primary-4;
        font-weight: bold;
    }

    .deleted {
        background-color: $opacity-gray-8;
        color: $gray-1;
    }
}

/** ADD DETAILS */
.add-details {
    position: relative;
    float: right;
    //width: 2em;
    padding: 0 0.5em;
    height: 1.5em;
    background-color: $success-2;
    color: white;
    cursor: pointer;
    border-radius: 0 0 10px 10px;
    border-top: none;
    text-align: center;

    &:hover {
        background-color: $success-1;
    }
}

/** ROUNDS */
.newRoundButton {
    //display: block;
    //position: absolute;
    //float: right;
    //right: 0;
    //top: -25px;
    //z-index: 10000;
}

/** CONTENT */
.content-card {
    font-size: 0.8em;

    h1, h2, h3, h4, h5, p {
        margin-bottom: 0.3em;
    }
}

// BID BUTTON
.bid-button {
    &.small {
        padding: 0.2em 0.8em;
        font-size: 0.9em;

        &.btn-icon {
            height: 1.5rem;
            min-width: 1.5rem;
            width: 1.5rem;
        }
    }
}

/** Customize element tabs */
.el-tabs {
    .el-tabs__item {
        &.is-active {
            span, a {
                color: $primary-2;
                font-weight: bold;
            }
        }

        a {
            color: $gray-2;
            text-decoration: none;
        }

        &:hover {
            a, span {
                color: $primary-2;
            }
        }
    }
}

/** MODAL STYLE - ELEMENT */
.el-dialog {
    min-height: 25vh;

    .el-dialog__header {
        background-color: $primary-2;
        color: $white-color;
        padding: 0.8em 2em;

        .el-dialog__title {
            color: $white-color;
            font-weight: bold;
            font-size: 1.4em;
        }

        .el-dialog__headerbtn {
            top: 12px;
            right: 12px;
        }
    }
}

/** List Group Style **/
.list-group-item-primary {
    color: $white-color;
    background-color: $primary-3;
}

.list-group-item-info {
    color: $white-color;
    background-color: $gray-1;
}

///** PANEL HEADER BACKGROUND */
//.panel-header {
//  background: #141E30; /* fallback for old browsers */
//  background: -webkit-gradient(linear, left top, right top, from(#0c2646), color-stop(60%, #204065), to(#2a5788));
//  background: linear-gradient(to right, #0c2646 0%, #204065 60%, #2a5788 100%);
//}

/** MEMBER */
.member-card {
    .card-header {
        padding: 0.4em 0.8em;
        background-color: $primary-3 !important;
        color: $white-color;
        font-weight: bold;
    }

    .initials-icon {
        display: inline-block;
        margin-left: 1px;
        margin-right: 10px;
        height: 26px;
        width: 26px;
        padding-top: 0.2em;
        font-size: 0.9em;
    }
}

/** MISCELLANEOUS ELEMENTS */
.edit-link {
    display: inline-block;
    float: right;
    padding: 0 2px 0 0;
    color: $gray-2;

    a {
        color: $gray-2;
        text-decoration: none;
    }

    &:hover {
        color: $primary-2;
        text-decoration: none;
    }
}

/* AUTH */
.card-login {
    color: $white-color;

    .logo {
        width: 250px !important;
        margin: 0 auto;
    }

    .fas, .form-control {
        color: $white-color;
    }

    .registration {
      background-color: rgba(0, 0, 0, 0.2);
      border: solid 1px white;
    }
}

/* REGISTRATION */
.card.card-wizard {
  background-color: rgba(0, 0, 0, 0.4);
  border: solid 1px white;
}
.card-wizard .card-header {
  padding: 0
}

/* SUMMARY */
.summary-leave-type {
    display: inline-block;
    margin: 0;
    padding: 0.1em 1em;
    border: 0 solid gray;
    border-radius: 25px;
    font-size: 0.8em;
    font-style: italic;
}

/* MISCELLANEOUS MODIFIERS */
.bg-lightgray {
    background-color: lightgray;
}

.bg-lightblue {
    background-color: $danger-0;
}

.cursor-default {
    cursor: default;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-help {
    cursor: help;
}

.el-dialog {
    // Tighten up ElementUI dialog
    .el-dialog__body {
      padding: 0.5em 1.5em;
    }

    .el-dialog__footer {
        padding: 0.5em 1.5em;
    }
}

.swal2-container {
    z-index: 9000 !important;
}

// Resize and tighten up ElementUI select (multi-select)
.el-select-dropdown {
    margin-top: -0.3em !important;
}

.el-select {
    .el-input__inner {
        height: 30px !important;
    }

    .el-select__tags {
        padding-top: 0.6em;
    }
}

/* Element Dropdown styling */
.el-dropdown-menu.el-popper {
    margin-top:0;
    padding-top: 0;
    padding-bottom: 0;
}
.el-popper[x-placement^=bottom] {
  margin-top: 5px !important;
}

/* INITIALS */
.initials-search { // SEARCH BOX
    width: 115px;

    .form-control {
        text-transform: uppercase;
    }
}

.initials-icon { // USER ICON
    width: 34px;
    height: 34px;
    overflow: hidden;
    float: left;
    z-index: 5;
    margin-left: 23px;
    margin-right: 10px;
    padding-top: 0.4em;
    background-color: $white-color;
    border-radius: 50%;
    -webkit-box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.3);
    text-align: center;
    font-weight: bold;
    color: $success-2;
}

/** Parallax */
.parallax {
    width: 100%;
    height: 570px;

    display: block;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.parallax .parallax-image {
    width: 100%;
    overflow: hidden;
    position: absolute;
}

.parallax .parallax-image img {
    width: 100%;
}

@media (max-width: 768px) {
    .parallax .parallax-image {
        width: 100%;
        height: 640px;
        overflow: hidden;
    }
    .parallax .parallax-image img {
        height: 100%;
        width: auto;
    }
}
