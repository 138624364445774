@import '../now-ui-dashboard/variables';

#leave-table {
    .table {
        tr.subhead:hover {
            transform: scale(1);
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            box-shadow: $box-shadow-raised;
            -webkit-box-shadow: $box-shadow-raised;
            -moz-box-shadow: $box-shadow-raised;
        }
    }
                    .month {
                        background-color: $primary-2;
                        font-weight: bold;
                        font-size: 0.8em;
                        color: $white-color;
                    }

                    .toggle-arrow {
                        width: 1em;
                        padding: 0.6em 0 0 0.2em;
                        font-weight: bold;
                        font-size: 1.2em;
                        line-height: 0.8em;
                        border: none;
                        cursor: pointer;
                        vertical-align: top;
                    }

    // DAY TABLE CELL
    .day {
        //width: 3.57%;
        padding: 0;
        vertical-align: top;
        cursor: default;
        background-color: white;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;

        .day-header {
            width: 100%;
            top: 0;
            left: 0;
            padding: 0.2em;
            font-size: 0.7em;
            font-style: italic;
        }

        .day-counts {
            width: 100%;
            font-size: 0.8em;
            font-weight: bold;
            text-align: center;
        }

        .day-content {
            width: 100%;
            padding: 0;
            font-size: 1em;
            font-weight: bold;
            text-align: center;
        }

        // SELECT DAYS
        &.weekend {
            background-color: lighten($primary-2, 50%);
        }

        &.wednesday {
            background-color: lighten($primary-2, 60%);
        }

        &.holiday {
            $holiday: &;

            background-color: $danger-1;

            &.holidayILO {
                background: repeating-linear-gradient(
                        45deg,
                        lighten($danger-1, 12%),
                        lighten($danger-1, 12%) 5px,
                        $white-color 5px,
                        $white-color 10px
                );
            }
        }

        &.selected {
            background-color: lighten($success-1, 30%);

            &.holiday {
                background: repeating-linear-gradient(
                        45deg,
                        $danger-1,
                        $danger-1 5px,
                        lighten($success-1, 30%) 5px,
                        lighten($success-1, 30%) 10px //lighten($success, 10%) 5px,
                    //lighten($md, 10%) 10px
                );
            }
        }

        &.dragged {
            background-color: lighten($success-2, 40%);
            cursor: cell !important;

            &.drag-anchor {
                background-color: lighten($success-0, 40%);
            }
        }
        &.drag-hover {
            background-color: lighten($success-2, 40%);
        }

        &.hovered {
            background-color: lighten($success-2, 40%);
        }

        // SLOT DISPLAY
        ul.slots {
            margin: 0;
            padding: 0;
            list-style: none;
            text-align: center;

            li {
                //display: flex;
                //align-items: flex-start;
                width: 85%;
                height: 30px;
                margin: 0 auto 5px auto;
                //margin: 0.4em 0 0.2em 0.3em;
                padding-top: 0.3em;
                background-color: darken($white-color, 25%);
                outline: 1px solid gray;
                font-size: 1.1em;
                font-weight: bold;
                text-transform: uppercase;

                span {
                    display: block;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                }

                .slot-crew {
                    height: 0;
                    overflow: visible;
                    float: right;
                    font-size: 0.4em;
                    margin-top: -5px;
                    margin-right: 4px;
                }

                &.taken {
                    background-color: darken($white-color, 15%);
                    color: darken($white-color, 35%);

                    &.deleted-slot {
                        background-color: $danger-0;
                        color: $white-color;
                    }
                }

                &.biddable {
                    background: rgb(231, 231, 231);
                    background: radial-gradient(circle, rgb(242, 242, 242) 0%, rgb(138, 173, 191) 95%);
                    cursor: pointer;
                    opacity: 1;

                    &:hover:not(.selected):not(.allowed) {
                        background: inherit;
                        background-color: lighten($primary-2, 40%);
                    }
                    &.allowed:hover {
                        background: $success-2;
                        opacity: 1;
                    }
                }

                &.allowed {
                    background: repeating-linear-gradient(
                            45deg,
                            $success-2,
                            $success-2 5px,
                            lighten($success-2, 30%) 5px,
                            lighten($success-2, 30%) 10px //lighten($success, 10%) 5px,
                        //lighten($md, 10%) 10px
                    );

                    &.biddable:not(.selected) {
                        background: lighten($success-2, 30%);
                        opacity: 1;
                    }

                    &:not(.selected) {
                        opacity: 0.7;
                    }
                }

                &.selected {
                    position: relative;
                    display: inline-block;
                    background: inherit;
                    background-color: $success-2;
                    color: $white-color;
                    text-align: center;
                    cursor: pointer;
                    //&:hover:before {
                    //  display: inline;
                    //  position: absolute;
                    //  content: 'x';
                    //  top: 0;
                    //  left: 0;
                    //  width: 100%;
                    //  height: 100%;
                    //  color: red;
                    //  font-size: 1.9em;
                    //  line-height: 1.0;
                    //  cursor: pointer;
                    //}
                }
            }
        }
    }
}

// LEAVE CART
.el-popover__title {
    margin-bottom: 0;
    padding-bottom: 0.4em;
    font-weight: bold;
    font-size: 1.5em;
    border-bottom: solid 1px $gray-0;
}

.member-leave {
    .row {
        margin-top: 1px;

        &.header {
            margin-top: 1em;
            margin-bottom: 5px;
            font-weight: bold;
            border-bottom: solid 1px $gray-2;
        }
    }
    .leave-desc {
      margin-bottom: 1px;
      white-space: pre-wrap;
      word-break: break-word;
    }

    i {
        color: $gray-1;
    }
}

.cart {
    .cart-week {

    }
    .cart-leave {
        margin-bottom: 0.6em;
        font-size: 0.8em;

        h6, b {
            color: $primary-2;
        }
    }

    .cart-week-title {
        font-weight: bold;
    }

    .cart-dates {
        padding: 0.3em 0;
        margin-bottom: 0;
        border-bottom: solid 1px $gray-2;

        .list-inline-item {
            margin: 0;
        }
    }
    .add-week {
        position: relative;
        float: right;
        //width: 100px;
        height: 20px;
        padding: 0.1em 0.8em 0.3em 0.8em;
        background-color: $success-2;
        color: white;
        cursor: pointer;
        border-radius: 0 0 10px 10px;
        border-top: none;
        text-align: center;

        a {
            color: white;
            text-decoration: none;
        }
        //&:hover {
        //    background-color: $success-color-opacity;
        //}
    }
}

