@import '../now-ui-dashboard/variables';

.flyover {
    position: fixed;
    width: 450px;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2000;
    background-color: $tan-0;
    font-size: 0.9rem;
    box-shadow: $sidebar-box-shadow;

    @media (max-width: 450px) {
        width: 100%;
    }

    .close {
        margin-top: -25px;
        font-size: 1rem;
        color: $gray-3;
    }

    .flyover-wrapper {
        position: relative;
        width: 450px;
        height: calc(100vh - 75px);
        overflow: auto;
        z-index: 1050;
        padding: 75px 25px;

        @media (max-width: 450px) {
            width: 100%;
        }

        .title {
            font-size: 1.2rem;
            font-weight: bold;
            color: $primary-2;
        }

        .details {
            margin-top: 15px;
            font-size: 1rem;
        }
    }
}

#lines {
    tr {
        //border: solid 2px $gray-4;
    }

    tr:not(.subhead):not(.head):hover {
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        box-shadow: $box-shadow-raised;
        -webkit-box-shadow: $box-shadow-raised;
        -moz-box-shadow: $box-shadow-raised;
    }

    td, th {
        border: solid 1px $primary-0;
    }

    /** Create draggable location for empty crew */
    .tbody-min-height {
        display: block;
        width: 1000px;
        min-height: 10px;
    }

    .detail-top-border {
        border-top: dashed 1px $primary-0;
    }

    .shift {
        border-right: solid 1px $primary-0;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;

        &.no-border {
            border: none;
        }
    }

    .rdo {
        background-color: $gray-0;
        color: $white-color;
        font-weight: bold;

        &:after {
            content: 'RDO';
        }
    }

    // Force full height for lines
    .el-tabs__content {
        min-height: 80vh;
        overflow: visible !important;
    }
}

.line-form {
    h6 {
        display: inline-block;
        width: 30%;
        margin-top: 1em;
        padding-right: 2em;
        padding-bottom: 0.2em;
        border-bottom: solid 1px $primary-2;
    }

    .shift-input-group {
        border: solid 1px $gray-1;
        border-radius: 20px;
        padding: .5em 0.5em;
    }

    .shift-start {
        display: inline-block;
        width: 75%;
        padding-left: 0.2em;
        border-style: none;

        &:focus {
            outline: none;
        }
    }

    .shift-length {
        display: inline-block;
        width: 25%;
        padding-left: 2px;
        text-align: center;
        border-style: none;
        border-left: solid 1px gray;

        &:focus {
            outline: none;
        }
    }
}

.fc-shift-display {
    background-color: $primary-2;
    color: $white-color;
    font-weight: bold;
    text-align: center;

    .fc-event-title {
        color: $white-color;
    }
}

.add-line-details {
    position: relative;
    float: right;
    width: 30px;
    height: 20px;
    background-color: $success-2;
    color: white;
    cursor: pointer;
    border-radius: 0 0 10px 10px;
    border-top: none;
    text-align: center;

    &:hover {
        background-color: $success-color-opacity;
    }
}

.rdo {
    background-color: darken($primary-2, 5%);
    color: $gray-1;
    font-weight: bold;

    &:after {
        content: 'RDO';
    }
}
