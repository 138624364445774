@import "../variables";

@mixin tag($type, $color){
  .el-tag,
  .el-tag.el-tag--#{$type} {
    @extend .badge, .badge-#{$type};
    color: $white-color;
    .el-tag__close{
      color: $white-color;
      opacity: 1;
    }
    .el-tag__close:hover{
      background-color: transparent;
      color: $white-color;
      font-size: $font-size-base;
      border-radius: 0;
    }
  }
}
.el-tag{
  margin-left:10px;
  margin-bottom:5px;
}
.input-new-tag{
  margin-left:10px;
  width: 80px !important;
  height: 24px;
  display: inline;

}

@include tag('info', $info-color);
@include tag('primary', $primary-color);
@include tag('success', $success-color);
@include tag('warning', $warning-color);
@include tag('danger', $danger-color);
@include tag('default', $default-color);
