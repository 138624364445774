/** PRIMARY */
$primary-0: #2A5687;
$primary-1: #698CB3;
$primary-2: #426A97;
$primary-3: #18416F;
$primary-4: #082D56;

/** SUCCESS */
$success-0: #22886B;
$success-1: #63B39D;
$success-2: #3B987E;
$success-3: #117056;
$success-4: #01563F;

/** WARNING */
$warning-0: #CFAB34;
$warning-1: #FFE58C;
$warning-2: #E7C75A;
$warning-3: #AB8919;
$warning-4: #846602;

/** DANGER */
$danger-0: #CF6C34;
$danger-1: #FFB58C;
$danger-2: #E78D5A;
$danger-3: #AB4E19;
$danger-4: #843102;

/** GRAYS */
$gray-0: #82868A;
$gray-1: #8D97A3;
$gray-2: #73797f;
$gray-3: #626972;
$gray-4: #535D69;

/** TANS */
$tan-0: #D3D0C6;
$tan-1: #F9F1D5;
$tan-2: #C3BEAE;
$tan-3: #AFA993;
$tan-4: #A0987D;

$font-color: $gray-3;
